.modalHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: var(--theme-div-background-light-1);
  }
  
  .modalTitle {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: var(--theme-page-title-color);
    font-size: var(--theme-font-size-slightly-bigger);
    font-weight: bold;
  }
  
  .modalSubTitle {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: var(--theme-page-title-color);
    font-size: var(--theme-font-size-normal);
    font-weight: bold;
    padding-bottom: 10px;
  }
  
  .modalBody {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10%;
    padding-top: 10%;
    max-height: 600px; /* set a max-height */
    overflow-y: auto; /* make it scrollable when content overflows vertically */
  }
  
  .modalBodyRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 10%;
    gap: 2%;
  }
  
  .buttonsRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5%;
    padding-left: 10%;
    padding-right: 10%;
  }
  
  .modalBodyRow_label {
    width: 15%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: var(--theme-font-size-normal);
  }
  
  .modalBodyRow_label_smaller {
    width: 15%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: var(--theme-font-size-small);
  }
  
  .modalBodyRow_input {
    flex: 1;
    /*width: 75%;*/
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .modalBodyRow_input_tf {
    width: 100%;
  }
  
  .vertical_element {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .horizontal_element {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 5%;
  }
  
  .english_tf {
    font-size: var(--theme-font-size-normal);
    font-family: var(--theme-font-family);
  }
  
  .arabic_tf {
    font-size: var(--theme-font-size-normal);
    font-family: var(--theme-font-family-arabic);
  }
  
  .modalBodyErrorRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-left: 10%;
    padding-right: 10%;
  }
  .modalBodyErrorRow_ErrorDiv {
    border: 1px solid rgba(255, 0, 0, 0.176);
    border-radius: 1rem;
    color: var(--theme-error-font-color);
    font-size: var(--theme-font-size-small);
    text-align: center;
    font-style: italic;
    padding: 5% 5% 5% 5%;
  }
  
  .modalBody_row_note {
    font-size: var(--theme-font-size-normal);
    justify-content: start;
    text-align: center;
    padding: 5% 5% 5% 5%;
  }
  
  .modalBody_row_importantNote {
    border: 1px solid rgba(255, 0, 0, 0.176);
    border-radius: 1rem;
    font-size: var(--theme-font-size-normal);
    text-align: center;
    font-style: italic;
    padding: 5% 5% 5% 5%;
  }
  
  .modalBody_alert_div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: var(--theme-font-size-normal);
    color: var(--theme-page-title-color);
    padding-bottom: 5%;
  }
  
  .modalBody_info_div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: var(--theme-font-size-normal);
    color: var(--theme-page-title-color);
    font-style: italic;
    padding-bottom: 5%;
  }