.registeration_container,
.login_container {
  border: 1px solid black;
  border-radius: 1rem;
  padding: 5% 3% 5% 3%;
  width: 30%;
  min-width: 250px;
  height: 40%;
  min-height: 500px;
  margin-top: 30px;
  margin-bottom: auto;
}

.registeration_form,
.login_form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 20px;
  gap: 20px;
}

.registeration_element,
.login_element {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 2%;
}

.input_element {
  padding-left: 5px;
  padding-right: 5px;
}

.select_div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /*align-items: center;*/
  padding-top: 5%;
  width: 100%;
}

.button_div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 20%;
}