.reg_request_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    width: 100%;
    max-width: 800px;
    max-height: fit-content;
  
    flex: 1;
  }
  
.reg_request_card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 0;
    width: 100%;
    flex: 1;
  
    border: 0.1rem solid rgba(67, 67, 67, 0.191);
    border-radius: 1rem;
  }
  
.reg_request_header {
    flex: 0.2;
    max-height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0 0.5rem 0 0.5rem;
    border-bottom: 0.1rem solid rgba(67, 67, 67, 0.191);
  
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  
    background-color: var(--theme-div-background-light-2);
  }
  
.reg_request_header_tile {
    font-size: 1.2rem;
    font-weight: bold;
  }
  
.reg_request_main_section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem;
  
    width: 100%;
    padding: 0.5rem;
  
    flex: 1;
  }
/*  
.reg_request_image {
    width: 100%;
    height: 200px;
    object-fit: cover;
}*/
  
.reg_request_info {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    padding: 0.5rem;
    gap: 0.5rem;
  
    overflow-y: auto;

    width: 90%;
    border: 1px solid rgba(0, 0, 0, 0.231);
    border-radius: 1rem;
}

.reg_request_info_text{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 0.5rem;
    padding: 0;
}

.reg_request_info_text p{
    margin: 0;
}

.reg_request_info_text_button{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
  
.reg_request_summary {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  
.reg_request_date {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: left;
    font-weight: bold;
}
  
.reg_request_footer {
    flex: 0.4;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20%;
    width: 100%;
    padding: 0 0.5rem 0 0.5rem;
    border-top: 0.1rem solid rgba(67, 67, 67, 0.191);
  
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
  
    font-size: 1.2rem;
    font-weight: bold;
  
    background-color: var(--theme-div-background-light-2);
}