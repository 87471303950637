.settings_form_container {
  background-color: rgba(255, 255, 255, 0.392);
  border: 1px solid black;
  border-radius: 1rem;
  padding: 5% 3% 5% 3%;
  width: 40%;
  min-width: 300px;
  height: 40%;
  min-height: 500px;
  margin-top: 30px;
  margin-bottom: 0.5rem;
}

.settings_form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 20px;
  gap: 20px;
}

.settings_element {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 2%;
}

.settings_input_element {
  padding-left: 5px;
  padding-right: 5px;
}



.settings_button_div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 20%;
}