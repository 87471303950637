/* The following is styling for LMS page*/
.multiple_card_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto; /* Automatically adjust height based on content */
  gap: 0.5rem; /* Space between child elements (like wrapper or placeholders) */
}

/*
.lms_courses_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start; 
  width: 100%;
  gap: 0.5rem;
  overflow-x: auto; 
  padding: 0; 
}*/

.multiple_card_container_wrapper {
  display: flex;
  flex-direction: row; /* Ensure cards are arranged horizontally */
  gap: 0.1rem; /* Space between cards */
  overflow-x: scroll; /* Enable horizontal scrolling with always visible scrollbar */
  width: 100%; /* Full width of the parent container */
  padding: 0; /* Avoid unnecessary padding affecting the scroll area */

  /* Ensure the scrollbar is always visible */
  scrollbar-width: auto; /* For Firefox: Default size, always visible */
  scrollbar-color: #888 #e0e0e0; /* Thumb and track colors for Firefox */
}

/* Webkit-based Browsers */
.multiple_card_container_wrapper::-webkit-scrollbar {
  height: 8px; /* Set the scrollbar height */
  background: #e0e0e0; /* Scrollbar track color */
}

.multiple_card_container_wrapper::-webkit-scrollbar-thumb {
  background: #888; /* Scrollbar thumb color */
  border-radius: 4px; /* Optional: Rounded edges for thumb */
}

.multiple_card_container_wrapper::-webkit-scrollbar-thumb:hover {
  background: #555; /* Darker thumb color on hover */
}
