.card {
  border: 1px solid #ddd;
  border-radius: 8px;
  width: 300px;
  min-width: 200px;
  height: 400px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  background-color: #fff;
  margin: 20px auto;

  margin: 0.2rem;

  overflow-y: auto;
}

.card:hover {
  box-shadow: 0 4px 6px var(--main-theme-color);
  cursor: pointer;
}

.image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  position: relative;
}

.placeholder {
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0;
  color: #aaa;
  font-size: 14px;
}

.title {
  display: flex; /* Enable flexbox */
  align-items: center; /* Vertically align the text */
  justify-content: center; /* Horizontally center the text */
  width: 100%;
  min-height: 100px;
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
  color: white; /* Ensures text is readable on the image */
  -webkit-text-stroke: 0.5px black; /* Adds a 1px black outline */
  position: absolute;
  top: 50%; /* Move to the vertical center */
  left: 50%; /* Move to the horizontal center */
  transform: translate(-50%, -50%); /* Center it exactly */
  background: rgba(
    0,
    0,
    0,
    0.3
  ); /* Optional: Add a semi-transparent background for readability */
  padding: 8px; /* Optional: Add some padding around the text */
  border-radius: 4px; /* Optional: Round the background edges */
}

.details {
  flex: 1;
  /*height: 100%;*/
  padding: 8px;
  /*color: var(--main-theme-color);*/
  display: flex;
  flex-direction: row;
  justify-content: center; /* Centers horizontally */
  align-items: center; /* Centers vertically */
  text-align: center;
}

*.noContent {
  text-align: center;
  color: #666;
  margin: 20px;
  font-size: 16px;
}
