.main_div {
  /*background-color: var(--main-div-background-color);*/
  background-color: white;
  width: 95vw;
  margin-top: 2%;
  margin-bottom: 2%;
  margin-left: auto;
  margin-right: auto;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 0.3rem solid var(--theme-color);
  border-radius: 0.5rem;
}

/*
  .title_div {
    display: flex;
    width: 100%;
    height: 12%;
    min-height: 200px;
    justify-content: center;
    align-items: center;
    background: linear-gradient(
      to bottom,
      var(--theme-div-background-dark-4),
      var(--theme-div-background-light-1)
    );
    color: var(--theme-page-title-color);
    font-size: 3rem;
    font-weight: bold;
  }*/


.body_div {
  width: 100%;
  height: 100%; /* Set to fill parent's height */
  flex: 1; /* Distribute remaining height to body_div */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}


.body_main_container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 0.6rem;
  gap: 0.5rem;
  margin: 0;
  background-color: var(--theme-div-background-light-3);
}

.body_sub_container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-grow: 1;
  background-color: var(--theme-div-background-light-2);
  padding: 0.5rem;
  border: 0.1rem solid rgba(67, 67, 67, 0.191);
  border-radius: 1rem;
  margin: 0.5rem 0;
}

.sub_container_title {
  font-size: var(--theme-font-size-normal);
  font-weight: bold;
  color: var(--main-theme-color);
}


@media screen and (max-width: 960px) {
  .main_div {
    width: 100vw;
    height: 100%;
    margin-top: 0%;
    margin-bottom: 0%;
    border-radius: 0%;
  }

  .body_div {
    width: 100vw;
    height: 100%;
    min-height: 100%;
    padding-bottom: 0%;
  }
}

.apology {
  color: var(--theme-color);

  margin: auto;
}

.titleText {
  color: var(--main-theme-color);
  font-weight: bold;
  font-size: var(--theme-font-size-slightly-bigger);
}

.importantText {
  color: var(--main-theme-color);
  font-weight: bold;
}

.importantTextPointer{
  color: var(--main-theme-color);
  font-weight: bold;
  cursor: pointer;
}

.textSmallerFont{
  font-size: var(--theme-font-size-smaller);
}

.importantTextPointerSmallerFont{
  color: var(--main-theme-color);
  font-weight: bold;
  cursor: pointer;
  font-size: var(--theme-font-size-smaller);
}
