.container {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content:space-between;
  align-items: center;
  gap: 1rem;
}

.container_mobile {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content:space-between;
  align-items: center;
  gap: 0.5rem;
}
