
.getStartedContainer {
    border: 1px solid black;
    border-radius: 1rem;
    padding: 3%;
    width: 50%;
    min-width: fit-content;
    height: 40%;
    min-height: 400px;
    margin: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .getStartedContainer h1{
    text-align: center;
  }
  
  .getStartedForm_mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 30px;
    gap: 20px;
  }
  
  .getStartedForm {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
    gap: 20px;
  }
  
  .getStartedElement {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 2%;
  }
  
  .input_element {
    padding-left: 5px;
    padding-right: 5px;
  }
  
  .select_div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /*align-items: center;*/
    padding-top: 5%;
    width: 100%;
  }
  
  .button_div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  