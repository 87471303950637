.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.319); /* Semi-transparent black */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .spinner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    font-size: 1rem;
    font-weight: bold;
    color: var(--main-theme-color);
  }