.navbar {
    /*background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);*/
    /*background-color: #502161;*/
    background-color: var(--main-div-background-color);
    /*background-image: var(--theme-background-image);*/
  
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    color: var(--primary-color);
  
    position: sticky;
    top: 0;
    z-index: 999;
  
    /*border-bottom: 3px solid rgb(250, 248, 248);*/
    transition: opacity 0.3s ease-in-out;
  }
  
  .navbar-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /*margin: 0 0 0 20px;*/
    height: 80px;
    width: 100vw;
  }
  /*
  .navbar-logo {
    justify-self: start;
    cursor: pointer;
    text-decoration: none;
    margin-left: 20px;
    font-size: 2rem;
    display: flex;
    align-items: center;
  
    border: none;
  }*/
  
  .navbar-logo{
    width: 80px;
    height: 80px;
    max-width: 80px;
    max-height: 80px;
    border: 3px solid rgba(255, 255, 255, 0.983);
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .navbar-lang-and-menu{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
  }
  
  .navbar-lang{
    text-decoration: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 20px
  }
  
  
  .nav-menu {
    display: flex;
    flex-direction: row;
    list-style: none;
    text-align: center;
    align-items: center;
    width: fit-content;
    justify-content: end;
    justify-self: center;
    text-decoration: none;
    margin-right: 10px;
    margin-bottom: 0;
    padding: 0;
  }
  
  .login_logout_container {
    display: flex;
    flex-direction: column;
    justify-self: center;
    align-items: center;
    justify-content: center;
  }
  
  .nav-item {
    height: 60px;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .nav-links {
    color: var(--main-theme-color);
  
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
  }
  
  .nav-links:hover {
    border-bottom: 4px solid #fff;
    transition: all 0.2s ease-out;
  }
  
  .nav-links-mobile {
    display: none;
  }
  
  .cart_container {
    display: flex;
    flex-direction: row;
    width: 60vw;
    height: 100%;
    justify-content: right;
    justify-self: center;
    align-items: center;
    margin-right: 20px;
  }
  .menu-icon {
    margin-left: auto;
    height: 100%;
    margin-right: 20px;
    font-size: 2rem;
  }
  
  .mobile-menu-icon {
    display: none; /*dont show on larger sceens*/
  }
  
  @media screen and (max-width: 1200px) {
    .navbar {
      background-color: white;
    }
    .NavbarItems {
      position: relative;
    }
  
    .nav-menu {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: left;
      width: 100%;
      position: absolute;
      top: 90px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
      padding-left: 0;
    }
  
    .nav-menu.active {
      background-color: var(--theme-div-background-light-2);
      left: 0;
      opacity: 0.9;
      transition: all 0.5s ease;
      z-index: 1;
      width: 100%;
    }
  
    .nav-item {
      text-decoration: none;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-self: center;
      width: 100%;
      padding: 2px 0;
    }
  
    .nav-links {
      text-align: center;
      width: 100%;
      display: table;
      padding: 5px 15px;
    }
  
    .nav-links:hover {
      background-color: #fff;
      color: var(--theme-div-background-dark-4);
      border-radius: 0;
    }
  
    .mobile-menu-icon {
      display: flex;
      align-items: center; /* Vertically center items */
      height: 100%;
      padding-right: 20px;
      cursor: pointer;
    }
  
    .fa-times,
    .fa-bars {
      font-size: 2rem;
      color: var(--main-theme-color);
      margin-left: 10px; /* Add some spacing between the icons and the logo */
    }
  
    .nav-links-mobile {
      display: block;
      text-align: center;
      margin: 2rem auto;
      border-radius: 4px;
      width: 80%;
      text-decoration: none;
      font-size: 1.5rem;
      background-color: transparent;
      padding: 14px 20px;
      border: 1px solid #fff;
      transition: all 0.3s ease-out;
    }
  
    .nav-links-mobile:hover {
      background: #fff;
      color: #b12828;
      transition: 250ms;
    }
  }